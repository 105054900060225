export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-6">
            <div className="row">
              <div className="section-title">
                <h2>LIÊN HỆ:</h2>
                <p>
                  Nếu gặp khó khăn trong quá trinh tương tác với chúng tôi, quý
                  khách vui lòng liên hệ qua số
                </p>
                <p>0977.111.701 (Mr. Nhật)</p>
                <p>0964.222.642 (Ms. Tiên)</p>
                <p>0977.218.664 (Mr. Nhật)</p>
              </div>
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Thông tin liên Hệ</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Địa chỉ
                </span>
                <div>{props.data ? props.data.address[0] : ""}</div>
                <div>{props.data ? props.data.address[1] : ""}</div>
                <div>{props.data ? props.data.address[2] : ""}</div>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12"></div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center"></div>
      </div>
    </div>
  );
};
