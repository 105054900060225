import { useState } from "react";
import { Image } from "./image";

export const Gallery = (props) => {
  const [showItem, setShowItem] = useState(6);
  console.log(showItem);
  const loadmoreHandler = () => {
    setShowItem(showItem + 6);
  };
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Bộ sưu tập</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.slice(0, showItem).map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
          <div>
            {showItem < 60 ? (
              <button
                className="btn btn-custom btn-lg page-scroll"
                onClick={loadmoreHandler}
              >
                Thêm
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
