export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/Image 03.JPG"
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>GIỚI THIỆU:</h2>
              <ul>
                <li>
                  {" "}
                  - “Kiến trúc Hoàng Gia – bạn của mọi nhà” lấy sự hài lòng của
                  khách hàng làm phương châm mũi nhọn.?
                </li>
                <li>
                  {" "}
                  - Chúng tôi luôn sẵn sàng đáp ứng mọi yêu cầu khó tính nhất
                  đến từ phía khách hàng trong mọi nét vẽ, hình ảnh Render, cho
                  đến từng nét chỉ trên công trình thực tế.
                </li>
                <li>
                  {" "}
                  - Công ty chúng tôi với đội ngũ nhân viên đông đảo, chuyên
                  nghiệp, tinh thần trách nhiệm cao sẽ luôn nỗ lực phấn đấu để
                  hoàn thành dự án một cách tỉ mỉ nhất.
                </li>
              </ul>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ol>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`} style={{ fontSize: 15 }}>
                            {" "}
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
